/*Note: Auth Reducer ***/

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    authuserdetail: null,
    authrefreshToken: null,
    authjwToken: null,
    userName: null,
    userId: null,
    addressList: null,
    getloactions: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {

        OTP: (state, action) => {
            let { payload } = action;
            console.log('payload:', payload);
            state.authuserdetail = payload;
            state.authrefreshToken = payload?.refreshToken;
            state.authjwToken = payload?.jwToken;
            state.userName = payload?.userName;
            state.userId = payload?.userId;
        },

        GETCUSTOMERDETAILS: (state, action) => {
            const { payload } = action;
            console.log('payload:', payload);
            state.authuserdetail = payload;
            state.addressList = payload?.details;
        },

        GETLOACTIONS: (state, action) => {
            const { payload } = action;
            console.log('payload:', payload);
            state.getloactions = payload;
        }

    }
});

export const {
    OTP,
    GETCUSTOMERDETAILS,
    GETLOACTIONS
} = authSlice.actions;

export default authSlice.reducer;