import React, { useCallback, useEffect, useRef, useState } from "react";
import './Signup.css'
import {
  Grid,
  Box,
  Card,
  TextField,
  Typography,
  Stack,
  Button,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import Group116683x from "../../../assets/Group 11668@3x.png";
import Group116672x from "../../../assets/Group 11667@2x.png";
import Group116003x from "../../../assets/Group 11600@3x.png";
import appleplaystore from "../../../assets/AAsS@3x.png";
import en_badge_web_generic from "../../../assets/en_badge_web_generic@3x.png";
import { useDispatch, useSelector } from "react-redux";
import { Getloactions, SignupUser } from "../../../redux/store/action/auth-action/auth-action";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
// import Modal from "react-modal";
import {
  GoogleMap,
  useJsApiLoader,

  Autocomplete,
  MarkerF,
} from "@react-google-maps/api";
import Loader from "../../../Loader/Loader";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from '@mui/material';

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const libraries = ["places"];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    // maxWidth: "600px",
  },
};

export const SignUp = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(false);

  const { authuserdetail, getloactions } = useSelector(({ authStates }) => authStates);


  useEffect(() => {
    dispatch(Getloactions())
  }, [])


  console.log("getloactions Data: ", getloactions);

  const [Name, setName] = useState(null);
  const [Email, setEmail] = useState(null);
  const [date_of_birth, setdate_of_birth] = useState(null);
  const [issueDate, setissueDate] = useState(null);
  const [cnic, setcnic] = useState(null);
  const [cnic_expiry, setcnic_expiry] = useState(null);
  const [phoneNumber, setphoneNumber] = useState(null);
  const [Address, setAddress] = useState(null);
  const [City, setCity] = useState(null);
  const [province, setprovince] = useState('Gilgit Baltistan');
  const [street, setstreet] = useState(null);
  const [houseNo, sethouseNo] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);


  // Map State And Ref Start Here 
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA4PO-2Fniyav15Hqg9a7tcDu9fFByJiF8",
    libraries
  });

  const [coords, setCoords] = useState({
    lat: Number(authuserdetail?.latitude) || center.lat,
    lng: Number(authuserdetail?.longitude) || center.lng
  });

  const [map, setMap] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  // Map State And Ref End Here 

  const currentDate = new Date();

  const handleChange = (e) => {
    const selectedDate = new Date(e.target.value);

    if (selectedDate >= currentDate) {
      toast.error("Please select a Correct Date of Birth!");
      setdate_of_birth('');
    } else {
      setdate_of_birth(e.target.value);
    }
  };

  const resHandler = (data) => {
    if (data) {
      setIsloading(false)
      setOpen(true);
    }
  };

  // Modal Function Start Here
  const [modalIsOpen, setIsOpen] = useState(true);
  const [Open, setOpen] = useState(false);

  // function openModal() {
  //   setOpen(true);
  // }

  // function closeModal() {
  //   setOpen(false);
  // }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setOpen(false);
  }

  // Maps Function Start Here
  useEffect(() => {
    getCurrentLocation();
  }, [dispatch]);

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCoords({ lat: latitude, lng: longitude });
        updateAddressFields(latitude, longitude);
      },
      () => {
        toast.error("Unable to retrieve your location");
      }
    );
  };

  const updateAddressFields = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        setAddress(results[0].formatted_address);

        const cityComponent = results[0].address_components.find(component => component.types.includes('locality'));
        if (cityComponent) {
          setCity(cityComponent.long_name);
        }
      } else {
        toast.error("Unable to retrieve address information");
      }
    });
  };

  const checkCityMatch = (cityName) => {
    const cityData = getloactions.find(item => item?.city?.toLowerCase() === cityName?.toLowerCase());
    if (cityData) {
      console.log("Matched City Data:", cityData);
      // Update any state or perform any actions needed with cityData
    } else {
      toast.error("Currently our services are not available in your area");
    }
  };

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(coords);
    map.fitBounds(bounds);
    setMap(map);
  }, [coords]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handlePlaceChanged = () => {
    if (autocompleteRef?.current) {
      const place = autocompleteRef?.current?.getPlace();

      if (place?.geometry) {
        const location = place?.geometry?.location;
        setCoords({
          lat: location?.lat(),
          lng: location?.lng()
        });
        map?.panTo(location);

        setAddress(place?.formatted_address);

        const cityComponent = place?.address_components.find(component => component.types.includes('locality'));
        if (cityComponent) {
          setCity(cityComponent?.long_name);
        }

        setShowInfoWindow(true);
      }
    }
  };

  const handleMapClick = (e) => {
    setCoords({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    updateAddressFields(e.latLng.lat(), e.latLng.lng());
    setShowInfoWindow(true);
  };

  const handleMarkerDragEnd = (e) => {
    setCoords({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    updateAddressFields(e.latLng.lat(), e.latLng.lng());
    setShowInfoWindow(true);
  };

  const selectLocation = () => {
    if (!Address) {
      toast.error("Please enter an Address!");
    } else {
      checkCityMatch(City); // Call checkCityMatch with the current city
      setIsOpen(false);
    }
  };


  const Register = () => {
    if (!(Name || "").trim()) {
      toast.error("Please enter a Name!");
    } else if (!(Email || "").trim()) {
      toast.error("Please enter a Email!");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Email || "")) {
      toast.error("Invalid Email Address!");
    } else if (!(date_of_birth || "").trim()) {
      toast.error("Please enter a Date Of Birth!");
    } else if (!(issueDate || "").trim()) {
      toast.error("Please enter a CNIC Issuance!");
    } else if (!(cnic || "").trim()) {
      toast.error("Please enter a CNIC Number!");
    } else if (cnic.length < 13) {
      toast.error("CNIC Number should be 13 digits!");
    } else if (!(cnic_expiry || "").trim()) {
      toast.error("Please enter a CNIC Expiry!");
    } else if (!(phoneNumber || "").trim()) {
      toast.error("Please enter a Contact Number!");
    } else if (!(Address || "").trim()) {
      toast.error("Please enter a Address!");
    } else if (!(street || "").trim()) {
      toast.error("Please enter a street!");
    } else if (!(houseNo || "").trim()) {
      toast.error("Please enter a House No!");
    } else if (!(province || "").trim()) {
      toast.error("Please Select a Province!");
    } else {
      setOpen(true);
    }
  };

  const SubmitRegister = () => {

    setIsloading(true)

    let formData = {
      phoneNumber: phoneNumber,
      name: Name,
      address: Address,
      email: Email,
      latitude: String(coords?.lat),
      longitude: String(coords?.lng),
      street: street,
      houseNo: houseNo,
      dateOfBirth: date_of_birth,
      defaultLoc: "",
      cnic: cnic,
      cnicIssueDate: issueDate,
      cnicExpiryDate: cnic_expiry,
      city: City,
      type: "CU",
      province: province,
      base64Image: "",
      version: "Web"
    }

    console.log(formData);

    dispatch(SignupUser(formData, resHandler));
  }

  return (
    <div>

      {/* Select Loaction Modal Start Here*/}
      <Modal
        open={modalIsOpen}
        style={customStyles}
        contentLabel="Location Modal"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="main_account_deletion_map_div">
          <div className="main_map_div">
            {isLoaded ? (
              <div className="inner_map_div">
                <div className="main_map_search_input_and_button_div">
                  <Autocomplete
                    onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                    onPlaceChanged={handlePlaceChanged}
                  >
                    <input
                      className='places-input'
                      type='text'
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </Autocomplete>
                </div>

                <div className="main_map_location_div">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={coords}
                    zoom={2}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    onClick={handleMapClick}
                    ref={mapRef}
                  >
                    <MarkerF
                      draggable={true}
                      position={coords}
                      onDragEnd={handleMarkerDragEnd}
                    />
                  </GoogleMap>
                </div>

                <Box style={{ width: "100%" }} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 2, mt: 2 }}>
                  <Button
                    style={{ width: "100%" }}
                    sx={{ borderRadius: 4, padding: "10px", border: "2px solid" }}
                    onClick={getCurrentLocation}
                  >
                    Select Current Location
                  </Button>
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    sx={{ borderRadius: 4, padding: "10px" }}
                    onClick={selectLocation}
                  >
                    Select Location
                  </Button>
                </Box>
              </div>
            ) : (
              <h1>Loading...!</h1>
            )}
          </div>
        </div>
      </Modal>
      {/* Select Loaction Modal End Here*/}

      {/* Terms And Condition Modal End Here*/}
      <Modal
        open={Open}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className='main_account_deletion_div'>
          <Typography
            id="modal-title"
            variant="h6"
            sx={{
              textAlign: "center",
              color: "#1591c7",
              fontWeight: "bold",
              fontSize: "30px",
              marginTop: "40px",
              marginBottom: "30px",
            }}
          >
            Terms And Conditions
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
              backgroundColor: "#dc0000",
            }}
          >
            <CloseIcon />
          </IconButton>

          <div className="MainTermsAndConditionsDiv">
            <div>The provision of Energy Service(s) by “Project Blue” to the Customer (as defined below) shall be governed by the terms and conditions stated herein below. </div>
            <div className="MainTermsAndConditions_innerheading_Div">1. DEFINITIONS</div>
            <div><b>“Affiliate(s)” </b> means any company or person that at any time during the term of this directly or indirectly Controls or is Controlled by, or is under common Control with a Party;</div>
            <div><b>“Account” </b> means a digital account created by a Customer on any PSO Application. </div>
            <div><b>“Bar Code” </b> means a unique identification strip that is placed on Project Blue Asset(s).</div>
            <div><b>“Blue Application” </b> means PSO or its Affiliate’s digital mobile application for Energy Service Delivery and associated services to Customer(s).</div>
            <div><b>“Conditions” </b> means the terms and conditions for the supply, delivery and use of PSO Assets  </div>
            <div><b>"Control" </b> (includes the term “Controlled”) and means the power to direct or cause the direction of the management and/or policies of a company, partnership, firm, or other entity or person, whether through ownership of its voting shares or by contract or otherwise including any merger, acquisition or disposal of another business. Without limiting the foregoing, for the purposes of this Agreement the ownership of shares carrying fifty percent (50%) or more of the voting rights or in case of less than (50%) voting rights, the exercise of management control in any manner (including through legislation or shareholder’s agreement) shall be deemed to constitute control;</div>
            <div><b>“Customer” </b> means the individual who places an Order for Energy Service Delivery through Blue Application.</div>
            <div><b>“Digicash” </b> means PSO’s digital wallet used for making virtual payments and which is subject to separate terms of use accepted by the Customer at the time of sign-up. </div>
            <div><b>“Delivery” </b> means delivery of the Project Blue Asset(s).</div>
            <div><b>“Delivery Address” </b> means the physical address communicated by the Customer through the Modes of Communication for Energy Service and delivery of Project Blue Assets.</div>
            <div><b>“Delivery Date” </b> means the approximate date for Energy Service delivery communicated through the Modes of Communication .</div>
            <div><b>“Energy Service” </b> means the delivery of Project Blue Assets and other energy related product and services provided by Project Blue in accordance with the Conditions stated herein.</div>
            <div><b>“LPG Cylinder” </b> means a cylinder of Liquified Petroleum Gas of Project Blue that is identifiable by a Bar Code.</div>
            <div><b>“Modes of Communication” </b> means and includes either singly or in combination the PSO Application(s), SMS, TAALUQ and PSO Website that shall be used as means of communication between PSO or its Affiliate(s) with the Customer(s). </div>
            <div><b>“Order” </b> means a request for delivery of PSO assets made by the Customer  through PSO Application(s).</div>
            <div><b>“Intellectual Property” </b> means all intellectual property rights arising from or associated with the following, whether created, protected or arising under the laws of Pakistan:</div>
            <div>trade names, trademarks and service marks (whether registered or unregistered), domain names and other internet addresses or identifiers, similar rights and applications to register any of the foregoing (collectively, <b>“Marks”</b>);</div>
            <div>copyrights (whether registered or unregistered) (collectively, <b>“Copyrights”</b> );</div>
            <div>know-how, inventions, discoveries, methods, processes, techniques, methodologies, formulae, algorithms, technical data, specifications, research and development information, technology, data bases and other proprietary or confidential information, including customer lists, but excluding any Copyrights that cover or protect any of the foregoing (collectively, <b>“Trade Secrets”</b> ); and</div>
            <div>any other proprietary, intellectual or industrial property rights of any kind or nature that do not comprise or are not protected by Marks, Copyrights or Trade Secrets.</div>
            <div><b>“Personal Data” </b> means inter alia names, phone numbers, CNIC, email address, residential address, payment details, Order details, location data, and any other information relating to an identified or identifiable individual, including through identification by means of online identifiers, device IDs, IP addresses or similar methods.</div>
            <div><b>“Project Blue Assets” </b> means an LPG Cylinder and other assets that PSO provides as part of its Energy Service. </div>
            <div><b>“Project Blue” </b> means PSO’s pilot project for Energy Service Delivery and associated services. </div>
            <div><b>“Project Blue Helpline” </b> means the helpline 0800-03000 available to Customer(s) for facilitation with Energy Service Delivery.</div>
            <div><b>“Project Blue Team” </b> means the personnel duly authorized by Project Blue to give effect to the Conditions stated herein.</div>
            <div><b>“PSO Applications” </b> means either single or collectively, as the context permits, the Blue Application, PSO Website, Digicash and any other application or website that may be introduced by PSO for use of the Energy Service.</div>
            <div><b>“QR Code” </b> means the unique digital identification code of Project Blue associated with each Order. </div>
            <div><b>“Territory” </b> means  the territorial jurisdiction within Pakistan  where  the Energy  Service will be provided.  </div>
            <div className="MainTermsAndConditions_innerheading_Div">2. REGISTERATION AND SERVICE TERMS</div>
            <div>The Customer shall be required to sign-up and register for PSO Applications and create an Account using a valid Computerized National Identification Number (“CNIC”) or a phone number registered on the Customer’s CNIC. </div>
            <div>The Customer shall be responsible for ensuring that information provided at the time of registration of the Account is accurate including name, email address, telephone and Delivery Address. </div>
            <div>With respect to LPG Cylinder(s), the Customer upon registration shall be presented with an option to self-select a period for recurring Delivery either for new cylinders or for refill <b>(“Recurring Delivery Date”)</b>. All Order(s) shall however be subject to confirmation by Project Blue and it is understood that Project Blue shall retain the right to decline an Order or communicate an alternate Delivery Date based on availability of inventory. </div>
            <div>The Customer may cancel an Order via Modes of Communication two (02) days prior to the Delivery Date. the Customer will be refunded any pre-paid amount via the PSO Application or through any other method in accordance with the Refund Policy.</div>
            <div>If an Order is accepted by Blue Application, a confirmation shall be issued specifying either full or partial confirmation of the Order on the basis of availability and Project Blue’s capacity to fulfil the Order <b>(“Confirmation”)</b>.</div>
            <div>The Customer shall notify Project Blue of any errors, deficiencies or inaccuracies in a   Confirmation immediately upon receipt of Confirmation and Project Blue shall, if necessary, issue a corrected Confirmation. For the avoidance of doubt, Project Blue shall not be responsible for any errors, deficiencies or inaccuracies in a Confirmation that are not timely notified by the Customer. </div>
            <div>In the context of the Recurring Delivery Date selected by the Customer, the Customer shall receive a reminder notification two (02) days prior to the scheduled Delivery and the Customer shall have the option to either confirm the Order or delay the reminder to the next day. In case the reminder is continuously delayed at the option of the Customer and Delivery on the Recurring Delivery Date is not confirmed, Blue Application shall allow the Customer a further period of ten (10) as a final opportunity to either confirm an alternate date for Delivery or notify Project Blue to make collection. </div>
            <div>Provided there are no Project Blue Assets in a Customer’s possession and no pending confirmed Order(s) awaiting Delivery, a Customer may cancel the Account and unsubscribe from the Energy Service. </div>
            <div>If a Customer is in possession of any Project Blue Asset(s), the Customer shall be unable to cancel the Account until the Project Blue Asset(s) is returned to Project Blue in accordance with these Conditions.</div>
            <div>For collection of the Project Blue Asset(s), Project Blue shall initiate periodical prompts via the PSO Applications or Modes of Communication communicating a time for collection of the Project Blue Asset(s). A thorough inspection will be conducted by the Project Blue Team and provided there is no damage, tampering or misuse of the Project Blue Asset(s), the full amount of Security Deposit shall be reimbursed to the Customer. </div>
            <div>All complaints will be directed through the PSO Applications or Modes of Communication. </div>
            <div>If these Conditions are breached in any manner, Project Blue shall have the right to withhold partially or fully the Security Deposit and in its discretion exercise the right to initiate legal action against the Customer. </div>
            <div className="MainTermsAndConditions_innerheading_Div">3. PRICE AND PAYMENT FOR LPG</div>
            <div>It is understood and agreed by the Customer that the price of LPG is notified by the Oil and Gas Regulatory Authority of Pakistan (“OGRA Price”) and is therefore subject to change from time to time.</div>
            <div>The Customer agrees to pay the prevailing OGRA Price and the additional service fees charged by Project Blue at its discretion for Delivery of the LPG Cylinder(s).</div>
            <div>Once an Order is placed via PSO Application(s), an invoice shall be generated on the respective PSO Application that shall showcase the number of cylinders ordered, category (new cylinder or refill), security deposit (only in case of first Order for new cylinder) and associated products and services. The Customer shall have the option to pay the invoice via Digicash or opt to pay cash on delivery or by other PSO Application(s) wallet. It shall be the Customer’s responsibility to ensure Digicash account or other PSO Application(s) account is topped up with sufficient funds for payment of the invoice.</div>
            <div className="MainTermsAndConditions_innerheading_Div">4. SECURITY DEPOSIT</div>
            <div>The amount of Security Deposit will remain with Project Blue till the cylinder(s) remains under the custody of the customer and provided back in satisfactory condition</div>
            <div>The Project Blue reserves the right to retain all or a portion of the Security Deposit to cover costs incurred due to damage and/or total loss to the cylinder(s) caused by the customer.</div>
            <div>In the event of subscription cancellation, the customer must submit a return request. Upon approval, the cylinder will be collected by the BLUE team within three business days. The Security Deposit will be refunded to the customer within ten business days, contingent upon the satisfactory inspection of the cylinder.</div>
            <div>Project Blue will be fully authorized to invest this security amount in any manner whatsoever and the customer will have no claim on the returns / profit / mark up etc. on such deposit.</div>
            <div className="MainTermsAndConditions_innerheading_Div">5. CUSTOMER OBLIGATIONS</div>
            <div>The Customer undertakes, agrees and represents as follows: </div>
            <div>The PSO Asset(s) shall only be for self-consumption.  </div>
            <div>The Customer shall be solely responsible for lawful use and consumption of PSO Asset(s) and undertakes to abide by general safety directions and any specific guidelines with respect to use of LPG Cylinders.  </div>
            <div>Store and use the LPG Cylinder in a safe and secure environment.  </div>
            <div>Shall bear all responsibilities, cost and risks with respect to handling, installation and operation of the PSO Asset(s).  </div>
            <div>All risks associated with the use and possession of the PSO Asset(s) shall transfer to the Customer upon Delivery by Project Blue. However, the ownership of PSO Asset(s) shall at all times remain Project Blue’s and the Customer shall not be permitted to engage with any third party. for the sale, lending or exchange of PSO Asset(s) (including refill of LPG Cylinder) within or outside the Territory limits.  </div>
            <div>The Customer shall be solely responsible for provision of accurate Personal Data and agrees that Project Blue shall reserve the right to cancel an Order without liability in case incorrect or discrepant information is provided. </div>
            <div>At the time of Delivery, the Customer shall present the QR Code on the invoice of the relevant Order and the Project Blue Team member shall  scan  the QR Code   to confirm Delivery of the Order. If no QR code is presented at the time of Delivery, Project Blue shall have the right to refuse Delivery. If any of the foregoing  conditions are not met or the Customer is unavailable at the time of Delivery, the Delivery shall be deemed unsuccessful and the next date of Delivery shall be communicated by Project Blue via Blue Application and SMS.  </div>
            <div>The Customer shall in no event be permitted to transport the LPG Cylinder(s) and change the location of the LPG Cylinder (s) from the one where Project Blue made Delivery. However, in case of a change of address, the Customer shall be responsible for contacting PSO via TAALUQ for notifying the change. Project Blue shall in such event have the right to collect the LPG Cylinder(s) in the Customer’s possession.</div>
            <div className="MainTermsAndConditions_innerheading_Div"> 6. INTELLECTUAL PROPERTY</div>
            <div>All Intellectual Property pertaining to the Service, PSO Applications and all information, data and material created for the promotion, marketing and provision of LPG Cylinders to Customers shall solely vest in PSO and any infringement of PSO’s Intellectual Property in any manner shall be actionable under applicable laws. It is understood that the Customer is not permitted to copy, reproduce, republish, upload, post, transmit, distribute, modify or use Intellectual Property of PSO in any manner unless authorized by PSO for a specific purpose. </div>
            <div className="MainTermsAndConditions_innerheading_Div">7. DATA PROTECTION AND CONFIDENTIALITY</div>
            <div>The Customer hereby provides its consent and explicitly authorizes PSO to collect, record, use and process Customer’s Personal Data in connection with registration and use of PSO Applications and in the course of PSO performing the Service. PSO undertakes that Customer’s Personal Data shall be used in accordance with prevailing and applicable laws and solely for performance of the Service. The confidentiality and integrity of Personal Data shall be protected by PSO on best effort basis but it is agreed that PSO will not bear liability for any harm to Personal Data arising from communication line failure, software viruses in Customer devices, theft, destruction, alteration or misuse by third parties who are either permitted access by the Customer or obtain unauthorized access illegally.</div>
            <div>The Customer further agrees and authorizes disclosure of Personal Data on a need to know basis to those third parties (including affiliates, partners, subsidiaries, consultants, advisors and representatives) that shall be associated with PSO for execution of the Service. It is further understood that PSO shall have no liability for any disclosure of Personal Data made in compliance with applicable laws.</div>
            <div className="MainTermsAndConditions_innerheading_Div">8. TERMINATION</div>
            <div>PSO shall have the right to terminate the Service without cause and at its convenience by providing reasonable prior notice to the Customer. In the event of termination, the Customer shall be permitted use of the Service until the Recurring Delivery Date but no further Delivery shall be made thereafter and the option of recurring Delivery shall automatically lapse. After termination of the Service, PSO shall promptly collect the LPG Cylinder(s) in the Customer’s possession and the date of collection shall be informed in advance. Security deposit will be returned in full to the customer at the time of LPG Cylinder recovery.</div>
            <div className="MainTermsAndConditions_innerheading_Div">9. INDEMNIFICATION</div>
            <div>The Customer shall indemnify and hold harmless PSO its employees, affiliates, subsidiaries, directors, officers, shareholders, contractors and representatives (“Indemnified Parties”) from and against any losses, damages, liabilities, expenses (including reasonable fee of attorney) costs, claims, suits, demands, actions, causes of action, proceedings, judgments and charges arising from Customer’s breach of these Conditions, use of the Service and use of PSO Applications.</div>
            <div className="MainTermsAndConditions_innerheading_Div">10. AMENDMENTS</div>
            <div>PSO shall have the right to amend these Conditions in its sole discretion and the amended Conditions shall be accessible to the Customer via PSO Applications. The amended Conditions shall become effective immediately upon publication on PSO Applications whether or not the same have been acknowledged by the Customer. It is the Customer’s sole responsibility to install updates and upgrades to the PSO Applications to remain aware of changes in the Conditions of use of the Service.</div>
            <div className="MainTermsAndConditions_innerheading_Div">11. GOVERNING LAW</div>
            <div>The Conditions and the Service shall be governed by and construed in accordance with the laws of the Islamic Republic of Pakistan and the courts of Islamabad, Pakistan shall have exclusive jurisdiction.</div>
          </div>

          <Stack direction="row" justifyContent="center" spacing={7} p={4}>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "#195389",
                "&:hover": {
                  backgroundColor: "#195389",
                },
              }}
              onClick={SubmitRegister}
            >
              Accept
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              onClick={closeModal}
            >
              Decline
            </Button>
          </Stack>
        </div>
      </Modal>
      {/* Terms And Condition Modal End Here*/}


      {isLoading ? (<Loader />) : (<div></div>)}

      <Box
        sx={{
          backgroundImage: `url('${Group116683x}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100dvh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: 2, sm: 4 },
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={6}
            md={6}
            lg={5}
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              mb: { xs: 1, md: 0 },
            }}
          >
            <img
              style={{ maxHeight: { xs: 300, md: 624 }, width: "100%", objectFit: "contain", marginTop: "30%" }}
              loading="lazy"
              src={Group116672x}
              alt="Illustration"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={7}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "100%", maxWidth: 500, p: { xs: 1, sm: 2 }, marginBottom: { xs: 4, sm: 0 } }}>
              <Card sx={{ padding: 3, borderRadius: 4 }}>
                <Stack sx={{ p: { xs: 0, sm: 3 }, rowGap: { xs: 2, sm: 4 } }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="blue_logo_img"
                      loading="lazy"
                      src={Group116003x}
                      alt="Logo"
                    />
                  </Box>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    columnGap={1}
                  >
                    <Typography
                      variant="h4"
                      fontWeight={"bold"}
                      textAlign="center"
                    >
                      Registration
                    </Typography>
                    <Typography fontSize="15px">
                      Already have an account?
                      <Link
                        href="/"
                        component="a"
                        sx={{ textDecoration: "none", ml: 1 }}
                      >
                        Login
                      </Link>
                    </Typography>
                  </Stack>
                  <TextField
                    id="outlined-multiline-static"
                    label="Name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <TextField
                    id="outlined-multiline-static"
                    label="Email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <TextField
                    id="outlined-multiline-static"
                    label="Date Of Birth"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    value={date_of_birth}
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    id="outlined-multiline-static"
                    label="CNIC Issuance"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    value={issueDate}
                    onChange={(e) => setissueDate(e.target.value)}
                    required
                  />
                  <TextField
                    id="outlined-multiline-static"
                    label="CNIC Number"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    value={cnic}
                    onChange={(e) => setcnic(e.target.value.slice(0, 13))}
                    required
                  />
                  <TextField
                    id="outlined-multiline-static"
                    label="CNIC Expiry"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    value={cnic_expiry}
                    onChange={(e) => setcnic_expiry(e.target.value)}
                    required
                  />
                  <FormControlLabel control={<Checkbox checked={cnic_expiry === "Lifetime"} onChange={(e) => setcnic_expiry(e.target.checked ? "Lifetime" : null)} />} label="Life Time Expiry" />
                  <TextField
                    id="outlined-multiline-static"
                    label="Contact Number"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    value={phoneNumber}
                    onChange={(e) =>
                      setphoneNumber(e.target.value.slice(0, 11))
                    }
                    required
                  />
                  <TextField
                    id="outlined-multiline-static"
                    label="Street"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    value={street}
                    onChange={(e) =>
                      setstreet(e.target.value)
                    }
                    required
                  />
                  <TextField
                    id="outlined-multiline-static"
                    label="House No"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    value={houseNo}
                    onChange={(e) =>
                      sethouseNo(e.target.value)
                    }
                    required
                  />
                  <TextField
                    id="outlined-multiline-static"
                    label="City"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ width: "100%" }}
                    value={City}
                    onChange={(e) =>
                      setCity(e.target.value)
                    }
                    // disabled
                    required
                  />
                  <FormControl InputLabelProps={{
                    shrink: true,
                  }}>
                    <InputLabel id="demo-simple-select-outlined-label"
                    // disabled
                    >Province</InputLabel>
                    <Select
                      // disabled
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={province}
                      onChange={(e) =>
                        setprovince(e.target.value)
                      }
                      label="Province"
                    >
                      <MenuItem value='Gilgit Baltistan' selected>Gilgit Baltistan</MenuItem>
                    </Select>
                  </FormControl >
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    minRows={4}
                    style={{ width: "100%" }}
                    label="Address"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    value={Address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  // disabled
                  />
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{ borderRadius: 5, fontSize: { xs: 15, sm: 20 }, fontWeight: "bold" }}
                    onClick={Register}
                  >
                    Send Verification Code
                  </Button>

                  <Stack direction="row" justifyContent="center" spacing={2} alignItems="center"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        style={{
                          width: "100%",
                          maxWidth: "150px",
                          height: "auto",
                        }}
                        loading="lazy"
                        src={en_badge_web_generic}
                        alt="Google Play Store"
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        style={{
                          width: "100%",
                          maxWidth: "130px",
                          height: "auto",
                        }}
                        loading="lazy"
                        src={appleplaystore}
                        alt="Apple App Store"
                      />
                    </Box>
                  </Stack>
                </Stack >
              </Card >
            </Box >
          </Grid >
        </Grid >
        <Stack
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            // p: 1,
            // backgroundImage: "linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%)",
          }}
        >
          {/* <Box>
            <Typography variant="body2" align="center" color="#fff">
              Powered by PSO
            </Typography>
          </Box> */}
          <div className="main_footer_div">
            <div className="inner_footer_div1">
              <p>
                <a href="/Privacy-Policy">
                  Privacy Policy
                </a>
              </p>
              <hr />
              <p>
                <a href="/Terms-and-Condition">
                  Terms and Condition
                </a>
              </p>
              <hr />
              <p>Account Deactivation</p>
            </div>
            <div className="inner_footer_div2">
              <p>© Copyright by PSO</p>
            </div>
          </div>
        </Stack>
      </Box >
    </div>
  );
};
