import React, { useCallback, useEffect, useRef, useState } from "react";
import './Manage-Address.css'
import { MdModeEdit } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import order_del_car from "../../../assets/order-del-car.png";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCalendarOutline } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import { FaArrowLeft } from "react-icons/fa";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FaRegCircle } from "react-icons/fa6";
import { AddAddress, SelectAddress, UpdateAddress } from '../../../redux/store/action/unauth-action/address-action/address-action';
import { GetCustomerDetails } from '../../../redux/store/action/auth-action/auth-action';
import { toast } from 'react-toastify';
import { FaMapLocationDot } from "react-icons/fa6";
import Modal from 'react-modal';
import { GoogleMap, useJsApiLoader, Autocomplete, MarkerF } from '@react-google-maps/api';
import Loader from "../../../Loader/Loader";


const containerStyle = {
    width: "100%",
    height: "100%",
};

const center = {
    lat: -3.745,
    lng: -38.523
};

const libraries = ['places'];

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        maxWidth: "600px",
    },
};

function Manage_Address() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(false);

    const phoneNumber = localStorage.getItem("phoneNumber");

    const { userId, addressList, authuserdetail } = useSelector(({ authStates }) => authStates);

    const [showmanageaddress, setshowmanageaddress] = useState(false)
    const [modalboxname, setmodalboxname] = useState('')

    const [Type, setType] = useState(null)
    const [Address, setAddress] = useState(null)
    const [City, setCity] = useState(null)
    const [province, setprovince] = useState(null)
    const [UpdateAddressid, setUpdateAddressid] = useState(null);
    const [street, setstreet] = useState(null);
    const [houseNo, sethouseNo] = useState(null);
    const [showInfoWindow, setShowInfoWindow] = useState(false);


    // Map State And Ref Start Here 
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyA4PO-2Fniyav15Hqg9a7tcDu9fFByJiF8",
        libraries
    });

    const [coords, setCoords] = useState({
        lat: Number(authuserdetail?.latitude) || center.lat,
        lng: Number(authuserdetail?.longitude) || center.lng
    });

    const [map, setMap] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const autocompleteRef = useRef(null);
    const mapRef = useRef(null);
    // Map State And Ref End Here 

    const manageaddress = (boxname, address) => {
        setmodalboxname(boxname);
        // console.log(address);
        setshowmanageaddress(true)

        if (address) {
            console.log("address: ", address);
            setType(address?.type);
            setAddress(address?.address);
            setstreet(address?.street)
            sethouseNo(address?.houseNo)
            setCity(address?.city);
            setprovince(address?.province);
            setUpdateAddressid(address?.id);
        }

    }

    const goback = () => {
        setType(null);
        setAddress(null);
        setCity(null);
        setprovince(null);
        setUpdateAddressid(null);
        sethouseNo(null)
        setstreet(null)
        setshowmanageaddress(false)
        setIsOpen(false);
    }

    const SelectAddressfunction = (address) => {
        // console.log(address);
        dispatch(SelectAddress(address))
    }

    useEffect(() => {
        dispatch(GetCustomerDetails(userId))
    }, [])


    // API res Handler Function Start Here
    const resHandler = (data) => {
        if (data) {
            dispatch(GetCustomerDetails(userId))
            goback();
            setIsloading(false)
        }
    }

    // Add Address Function Start Here
    const addaddressfunction = () => {

        if (!Type.trim()) {
            toast.error("Please enter a Type!");
        } else if (!Address.trim()) {
            toast.error("Please enter an Address!");
        } else if (!City.trim()) {
            toast.error("Please enter a City!");
        } else if (!street) {
            toast.error("Please enter a street!");
        } else if (!street.trim()) {
            toast.error("Please enter a street!");
        } else if (!houseNo.trim()) {
            toast.error("Please enter a House No!");
        } else if (!province.trim()) {
            toast.error("Please select a Province!");
        } else {

            setIsloading(true)

            let formData = {
                phoneNumber: phoneNumber,
                userGUID: userId,
                address: Address,
                street: street,
                houseNo: houseNo,
                city: City,
                type: Type,
                province: province,
                longitude: String(coords.lng),
                latitude: String(coords.lat)
            }

            dispatch(AddAddress(formData, resHandler))
        }

    }

    // Update Address Function Start Here
    const updateaddressfunction = () => {

        if (!Type.trim()) {
            toast.error("Please enter a Type!");
        } else if (!Address.trim()) {
            toast.error("Please enter a Address!");
        } else if (!City.trim()) {
            toast.error("Please enter a City!");
        } else if (!street.trim()) {
            toast.error("Please enter a street!");
        } else if (!houseNo.trim()) {
            toast.error("Please enter a House No!");
        } else if (!province.trim()) {
            toast.error("Please Select a Province!");
        } else {

            setIsloading(true)

            let formData = {
                customerAddressId: UpdateAddressid,
                address: Address,
                street: street,
                houseNo: houseNo,
                city: City,
                type: Type,
                province: province,
                longitude: String(coords.lng),
                latitude: String(coords.lat)
            }

            dispatch(UpdateAddress(formData, resHandler))
        }

    }



    // Modal Function Start Here
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setInputValue('');
        setIsOpen(false);
    }

    // Maps Function Start Here
    useEffect(() => {
        getCurrentLocation();
    }, []);

    const getCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setCoords({ lat: latitude, lng: longitude });
                updateAddressFields(latitude, longitude);
            },
            () => {
                toast.error("Unable to retrieve your location");
            }
        );
    }

    const updateAddressFields = (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK' && results[0]) {
                setAddress(results[0].formatted_address);

                const cityComponent = results[0].address_components.find(component => component.types.includes('locality'));
                if (cityComponent) {
                    setCity(cityComponent.long_name);
                }
            } else {
                toast.error("Unable to retrieve address information");
            }
        });
    };

    const onLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds(coords);
        map.fitBounds(bounds);
        setMap(map);
    }, [coords]);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const handlePlaceChanged = () => {
        if (autocompleteRef?.current) {
            const place = autocompleteRef?.current?.getPlace();

            if (place?.geometry) {
                const location = place?.geometry?.location;
                setCoords({
                    lat: location?.lat(),
                    lng: location?.lng()
                });
                map?.panTo(location);

                setAddress(place?.formatted_address);

                const cityComponent = place?.address_components.find(component => component.types.includes('locality'));
                if (cityComponent) {
                    setCity(cityComponent?.long_name);
                }

                setShowInfoWindow(true);
            }
        }
    };

    const handleMapClick = (e) => {
        setCoords({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        updateAddressFields(e.latLng.lat(), e.latLng.lng());
        setShowInfoWindow(true);
    };

    const handleMarkerDragEnd = (e) => {
        setCoords({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        updateAddressFields(e.latLng.lat(), e.latLng.lng());
        setShowInfoWindow(true);
    };

    const selectLocation = () => {
        if (!Address) {
            toast.error("Please enter an Address!");
        } else {
            setIsOpen(false);
        }
    };



    return (
        <>
            {isLoading ? (<Loader />) : (<></>)}

            <div className='main_order_list_div'>

                <div className='main_order_list_tabs_section_div'>
                    <div> <Link to='/My-Profile'>My Profile</Link></div>
                    <div> <Link to='/order-list'>Order list</Link></div>
                    <div className='order_list_tab_active'> <Link to='/manage-address'>Manage Address</Link></div>
                    <div> <Link to='/Manage-Cards'>Manage Cards</Link></div>
                    <div> <Link to='/wallet'>Wallet</Link></div>
                </div>
                {showmanageaddress ?
                    (
                        <div className='main_add_address_div'>

                            <div className='main_add_address_back_arrow_div'><FaArrowLeft onClick={goback} /> <span>{modalboxname}</span></div>

                            <div className='inner_header_add_address_div'>
                                <TextField
                                    label="Type"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: "100%" }}
                                    value={Type}
                                    onChange={(e) => setType(e.target.value)}
                                />
                                <div
                                    className="select_address_div"
                                >
                                    <FaMapLocationDot onClick={openModal} />
                                    <TextField
                                        label="Address"
                                        variant="outlined"
                                        multiline
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        minRows={4}
                                        style={{ width: "100%" }}
                                        value={Address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    // disabled
                                    />
                                </div>

                                <TextField
                                    label="Street"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    value={street}
                                    onChange={(e) =>
                                        setstreet(e.target.value)
                                    }
                                />
                                <TextField
                                    label="House No"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    value={houseNo}
                                    onChange={(e) =>
                                        sethouseNo(e.target.value)
                                    }
                                />

                                <TextField
                                    label="City"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: "100%" }}
                                    value={City}
                                    onChange={(e) => setCity(e.target.value)}
                                // disabled
                                />

                                <FormControl variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ width: "100%" }}
                                >
                                    <InputLabel id="demo-simple-select-outlined-label">Province</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={province}
                                        onChange={(e) =>
                                            setprovince(e.target.value)
                                        }
                                        label="Province"
                                    >
                                        <MenuItem value='Gilgit Baltistan'>Gilgit Baltistan</MenuItem>
                                    </Select>
                                </FormControl>

                                <div className='main_add_address_save_button_div'>
                                    {modalboxname === 'Update Address' ?
                                        <button onClick={updateaddressfunction}>Save</button>
                                        :
                                        <button onClick={addaddressfunction}>Save</button>
                                    }
                                </div>

                            </div>

                        </div>
                    ) : (
                        <div className='main_manage_address_div'>

                            <div className='main_add_address_section_div'>
                                <div className='inner_add_address_section_div'>
                                    <FaPlus onClick={() => manageaddress("Add Address", '')} />
                                </div>
                            </div>

                            {addressList.map((address, index) => (
                                <div key={index} className='main_manage_address_card_div'>
                                    <div className='main_manage_address_card_body_div' onClick={() => SelectAddressfunction(address)}>
                                        <div className='main_manage_address_card_header_heading_div'>{address.type}</div>
                                        <div> <b style={{ color: "black" }}>Address: </b> {address.address}</div>
                                        <div><b style={{ color: "black" }}>province: </b>{address.province}</div>
                                        <div><b style={{ color: "black" }}>City: </b>{address.city}</div>
                                    </div>
                                    <div className='main_manage_address_card_header_div'>
                                        <div className='main_manage_address_card_header_edit_div'><MdModeEdit onClick={() => manageaddress("Update Address", address)} /></div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    )
                }
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >

                <div className="main_map_div">

                    {isLoaded ? (
                        <div className="inner_map_div">
                            <div className="main_map_search_input_and_button_div">
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                                    onPlaceChanged={handlePlaceChanged}
                                >
                                    <input
                                        className='places-input'
                                        type='text'
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                    />
                                </Autocomplete>
                            </div>

                            <div className="main_map_location_div">
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={coords}
                                    zoom={9}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                    onClick={handleMapClick}
                                    ref={mapRef}
                                >
                                    <MarkerF
                                        draggable={true}
                                        position={coords}
                                        onDragEnd={handleMarkerDragEnd}
                                    />
                                </GoogleMap>
                            </div>

                            <Box style={{ width: "100%" }} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 2, mt: 2 }}>
                                <Button
                                    style={{ width: "100%" }}
                                    sx={{ borderRadius: 4, padding: "10px", border: "2px solid" }}
                                    onClick={getCurrentLocation}
                                >
                                    Select Current Location
                                </Button>
                                <Button
                                    style={{ width: "100%" }}
                                    variant="contained"
                                    sx={{ borderRadius: 4, padding: "10px 40px" }}
                                    onClick={selectLocation}
                                >
                                    Select Location
                                </Button>
                            </Box>
                        </div>
                    ) : (
                        <h1>Loading...!</h1>
                    )}
                </div>

            </Modal>

        </>
    )
}

export default Manage_Address
