import React, { useEffect, useMemo, useState } from "react";
import "./Order-Now.css";
import refill_cylinder_img from "../../../assets/refill-cylinder.png";
import new_cylinder_img from "../../../assets/new-cylinder.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Refill from "./Refill/Refill";
import New_cylinder from "./New-cylinder/New-cylinder";
import { useDispatch, useSelector } from "react-redux";
import { FaCalendarAlt } from "react-icons/fa";
import { Box, Button, Divider, IconButton, Modal, Stack, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AddAddress, SelectAddress } from "../../../redux/store/action/unauth-action/address-action/address-action";
import { useNavigate } from "react-router-dom";


function Order_Now() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { scheduledorderList, orderList } = useSelector(({ orderStates }) => orderStates);
  const { selectaddress } = useSelector(({ addressStates }) => addressStates);
  const { addressList, userId } = useSelector(({ authStates }) => authStates);
  const phoneNumber = localStorage.getItem("phoneNumber");

  // const [openModalcheckaddress, setopenModalcheckaddress] = useState(false);
  // const [currentDate, setCurrentDate] = useState('');
  // const [rowData, setRowData] = useState(null);
  // const [type, setType] = useState(null);
  // const [lastOrderAddress, setLastOrderAddress] = useState('');
  // const [lastOrderCity, setLastOrderCity] = useState('');
  // const [lastOrderProvince, setLastOrderProvince] = useState('');
  // const [lastOrderLang, setLastOrderLang] = useState(null);
  // const [lastOrderLat, setLastOrderLat] = useState(null);

  // const handleopenModalcheckaddress = (row) => {
  //   setRowData(row.original);
  //   setopenModalcheckaddress(true);
  // };

  // const handleCloseModal = () => {
  //   setopenModalcheckaddress(false);
  // };

  // const completedOrders = orderList?.orders?.filter(row => row?.status === 'Delivered');
  // const reversedCompletedOrders = completedOrders?.slice().reverse();

  // const fetchAddressFromCoordinates = async (lat, lng) => {
  //   try {
  //     const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA4PO-2Fniyav15Hqg9a7tcDu9fFByJiF8`);
  //     const data = await response.json();
  //     if (data?.status === 'OK') {
  //       const address = data?.results[0].formatted_address;
  //       const addressComponents = data?.results[0].address_components;
  //       const city = addressComponents?.find(component => component?.types.includes('locality'))?.long_name || '';
  //       const province = addressComponents?.find(component => component?.types.includes('administrative_area_level_1'))?.long_name || '';

  //       setLastOrderAddress(address);
  //       setLastOrderCity(city);
  //       setLastOrderProvince(province);

  //       return address;
  //     } else {
  //       console.error('Error fetching address:', data?.status);
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error('Error fetching address:', error);
  //     return null;
  //   }
  // };

  // const calculateDistanceAndopenModalcheckaddress = () => {
  //   const lastOrder = reversedCompletedOrders?.[0];
  //   if (!lastOrder) return;

  //   const lastOrderLang = lastOrder?.currentScheduleStatus?.orderAtLang;
  //   const lastOrderLat = lastOrder?.currentScheduleStatus?.orderAtLat;
  //   const currentAddressLang = lastOrder?.currentScheduleStatus?.selectedLongWhenPlacedOrder;
  //   const currentAddressLat = lastOrder?.currentScheduleStatus?.selectedLatWhenPlacedOrder;

  //   if (lastOrderLat && lastOrderLang) {
  //     setLastOrderLang(lastOrderLang);
  //     setLastOrderLat(lastOrderLat);
  //     fetchAddressFromCoordinates(lastOrderLat, lastOrderLang);
  //   }

  //   if (lastOrderLang && lastOrderLat && currentAddressLang && currentAddressLat) {
  //     const haversineDistance = (coords1, coords2) => {
  //       const toRad = (x) => x * Math.PI / 180;
  //       const R = 6371e3; // Earth's radius in meters

  //       const dLat = toRad(coords2.lat - coords1.lat);
  //       const dLon = toRad(coords2.lon - coords1.lon);
  //       const lat1 = toRad(coords1.lat);
  //       const lat2 = toRad(coords2.lat);

  //       const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  //         Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  //       const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  //       return R * c; // Distance in meters
  //     };

  //     const distance = haversineDistance(
  //       { lat: lastOrderLat, lon: lastOrderLang },
  //       { lat: currentAddressLat, lon: currentAddressLang }
  //     );

  //     console.log("Distance data: ", distance);

  //     const isLastOrderAddressInList = addressList?.some(
  //       address => address?.longitude === lastOrderLang && address?.latitude === lastOrderLat
  //     );

  //     if (distance > 25 && !isLastOrderAddressInList) {
  //       setopenModalcheckaddress(true); // Set state to open modal
  //     }
  //   }
  // };

  // useEffect(() => {
  //   calculateDistanceAndopenModalcheckaddress();
  // }, [addressList]);

  // const resHandlerModal = (data) => {
  //   if (data) {
  //     // let formData = {
  //     //   "phoneNumber": phoneNumber,
  //     //   "userGUID": userId,
  //     //   "address": lastOrderAddress,
  //     //   "street": "",
  //     //   "houseNo": "",
  //     //   "city": lastOrderCity,
  //     //   "type": type,
  //     //   "province": lastOrderProvince,
  //     //   "longitude": String(lastOrderLang),
  //     //   "latitude": String(lastOrderLat)
  //     // };
  //     // dispatch(SelectAddress(formData))

  //     setopenModalcheckaddress(false);
  //     navigate('/manage-address')

  //   }
  // };

  // const handleYes = () => {
  //   let formData = {
  //     "phoneNumber": phoneNumber,
  //     "userGUID": userId,
  //     "address": lastOrderAddress,
  //     "street": "",
  //     "houseNo": "",
  //     "city": lastOrderCity,
  //     "type": type,
  //     "province": lastOrderProvince,
  //     "longitude": String(lastOrderLang),
  //     "latitude": String(lastOrderLat)
  //   };

  //   dispatch(AddAddress(formData, resHandlerModal));
  // };


  return (
    <>

      {/* Check You Current Delivery Address And Last Delivery Address Modal Start Here */}
      {/* <Modal
        open={openModalcheckaddress}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          className="main_modal_div"
        >
          <Typography
            id="modal-title"
            variant="h6"
            sx={{
              textAlign: "center",
              color: "#1591c7",
              fontWeight: "bold",
              fontSize: "30px",
              marginBottom: "10px",
            }}
          >
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
              backgroundColor: "#dc0000",
            }}
          >
            <CloseIcon />
          </IconButton>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: ".5rem"
            }}>
            <Typography
              sx={{
                color: "gray",
                fontSize: "17px",
                fontWeight: "bold",
                textAlign: "center",
                paddingBottom: "10px"
              }}
            >
              Your Current Delivery Address Is
            </Typography>
            <Typography
              sx={{
                color: "gray",
                fontSize: "17px",
                fontWeight: "bold",
                textAlign: "center",
                paddingBottom: "10px"
              }}
            >
              {selectaddress.address.length > 30
                ? `${selectaddress.address.substring(0, 30)}...`
                : selectaddress.address}
            </Typography>
            <Typography
              sx={{
                color: "#1591c7",
                fontSize: "17px",
                fontWeight: "bold",
                textAlign: "center",
                paddingBottom: "10px"
              }}
            >
              Your Last Delivery Address Was
            </Typography>
            <Typography
              sx={{
                width: "80%",
                color: "#1591c7",
                fontSize: "15px",
                fontWeight: "bold",
                textAlign: "center",
                paddingBottom: "10px"
              }}
            >
              {lastOrderAddress}
            </Typography>
            <TextField
              label="Address Type"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "60%" }}
              value={type}
              onChange={(e) => setType(e.target.value)}
            />
            <Typography
              sx={{
                width: "50%",
                color: "#000",
                fontSize: "17px",
                fontWeight: "bold",
                textAlign: "center",
                paddingBottom: "10px"
              }}
            >
              Do you want to Continue Your Delivery on Current Address
            </Typography>
          </div>
          <Stack direction="row" justifyContent="center" spacing={3} p={0}>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              onClick={() => handleYes()}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              onClick={handleCloseModal}
            >
              No
            </Button>
          </Stack>
        </div>
      </Modal> */}
      {/* Check You Current Delivery Address And Last Delivery Address Modal End Here */}

      <div className="main_order_detail_div">
        <Tabs>
          <TabList>
            {scheduledorderList?.length === 0 ? null :
              <Tab>
                <div className="order_detail_tab_div">
                  <div>
                    <img src={refill_cylinder_img} alt="" />
                  </div>
                  <div>Refill</div>
                </div>
              </Tab>
            }

            <Tab>
              <div className="order_detail_tab_div">
                <div>
                  <img src={new_cylinder_img} alt="" />
                </div>
                <div>New cylinder</div>
              </div>
            </Tab>
          </TabList>
          {scheduledorderList.length === 0 ? null :
            <TabPanel>
              <Refill />
            </TabPanel>
          }

          <TabPanel>
            <New_cylinder />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}

export default Order_Now;
